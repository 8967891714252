.page-container {
  --page-x-padding: 40px;
  --nav-top-padding: 20px;
  --nav-bottom-padding: 10px;
  --nav-content-height: 40px;
  --nav-container-height: calc(var(--nav-content-height) + var(--nav-top-padding) + var(--nav-bottom-padding));
  --child-container-min-height: var(--nav-content-height);
  --child-container-height: calc(max(var(--child-container-min-height), 100vh - var(--nav-container-height)));
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(var(--child-container-min-height) + var(--nav-container-height));
}

.navbar-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: var(--nav-container-height);
  padding: var(--nav-top-padding) var(--page-x-padding) var(--nav-bottom-padding) var(--page-x-padding);
}

.child-container {
  display: inline-block;
  flex-grow: 1;
  width: 100%;
  padding: 0 var(--page-x-padding) 0 var(--page-x-padding);
}
