@media all and (min-width: 480px) {
  .authentication {
    width: 100%;
    padding-top: 60px;
  }

  .authentication form {
    margin: 0 auto;
    max-width: 320px;
  }
}
