.home-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }

  .alarm-log-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }
  
  .alarm-log-table {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .alarm-log-table th span {
    justify-content: center;
  }

  